const shortArrow = {
  viewBox: '0 0 38 28',
  d: 'M37.785 13.833c-7.64 0-13.833-6.193-13.833-13.833M37.785 13.833c-7.64 0-13.833 6.193-13.833 13.833M34.262 13.833H0',
};

const longArrow = {
  viewBox: '0 0 1600 240',
  d: 'M0 113.309c481.047-4.843 994.38-4.843 1540 0-23.053-17.296-38.35-32.365-45.895-45.207-7.543-12.843-14.431-35.543-20.664-68.102h20.664c0 33.456 10.564 60.122 31.692 80 21.127 19.878 45.862 30.98 74.203 33.309v15.504c-20.112-.562-42.79 7.804-68.031 25.097-25.242 17.293-37.864 45.99-37.864 86.09h-20.664c5.414-31.78 12.302-54.655 20.664-68.625 8.363-13.97 23.661-28.157 45.895-42.563H0c-32.286-10.01-32.286-15.178 0-15.503z'
};

const iconDivider = {
  viewBox: '0 0 320 58',
  d: 'M8.5 43a6.5 6.5 0 110 13 6.5 6.5 0 010-13zm152 0a6.5 6.5 0 110 13 6.5 6.5 0 010-13zm151 0a6.5 6.5 0 110 13 6.5 6.5 0 010-13zM31 18c6.075 0 11 4.925 11 11s-4.925 11-11 11-11-4.925-11-11 4.925-11 11-11zm258 0c6.075 0 11 4.925 11 11s-4.925 11-11 11-11-4.925-11-11 4.925-11 11-11zM8.5 2a6.5 6.5 0 110 13 6.5 6.5 0 010-13zm152 0a6.5 6.5 0 110 13 6.5 6.5 0 010-13zm151 0a6.5 6.5 0 110 13 6.5 6.5 0 010-13zM13 13l9 9m285-9l-9 9m8 24l-9-9M22 36l-9 9m29-17L154 8m124 19L167 8M42 31l112 18m124.227-17L167 49M42 29l235.5.5',
};

const iconEye = {
  viewBox: '0 0 228 228',
  d: 'M114 2c61.856 0 112 50.144 112 112s-50.144 112-112 112S2 175.856 2 114 52.144 2 114 2zm0 2C53.25 4 4 53.249 4 114s49.249 110 110 110 110-49.249 110-110S174.751 4 114 4zm-.446 67c31.821 0 59.6 17.243 74.553 42.882-14.953 25.639-42.732 42.882-74.553 42.882-14.033 0-27.28-3.353-38.988-9.301A86.71 86.71 0 0139 113.882C53.954 88.243 81.732 71 113.554 71zm.001 2C82.587 73 55.553 89.438 41 113.88c8.135 13.663 20.17 24.825 34.613 32.013 11.393 5.67 24.285 8.867 37.942 8.867 30.968 0 58.003-16.438 72.555-40.88C171.558 89.438 144.523 73 113.555 73zm.687 14c15.046 0 27.243 12.197 27.243 27.243s-12.197 27.243-27.243 27.243C99.197 141.486 87 129.289 87 114.243S99.197 87 114.242 87zm-.002 2C100.3 89 89 100.302 89 114.245c0 13.943 11.3 25.245 25.24 25.245 13.94 0 25.24-11.302 25.24-25.245 0-13.943-11.3-25.245-25.24-25.245z'
};

const iconQuote = {
  viewBox: '0 0 254 25',
  d: 'M12.405.506c6.47 0 11.766 5.13 12.03 11.537l229.183-.501v1.945l-229.258.507c-.717 5.96-5.805 10.593-11.955 10.593-6.639 0-12.04-5.401-12.04-12.04C.364 5.907 5.765.506 12.404.506zm0 2C6.86 2.506 2.365 7 2.365 12.546c0 5.546 4.495 10.04 10.04 10.04 5.545 0 10.041-4.494 10.041-10.04 0-5.545-4.496-10.04-10.04-10.04z'
};

const selectArrow = {
  viewBox: '0 0 29 16',
  d: 'M.834 2.455c4.376.906 7.343 2.138 8.9 3.696 1.557 1.557 2.866 4.602 3.926 9.134h1.992c.986-4.396 2.327-7.441 4.022-9.134 1.696-1.694 4.638-2.926 8.826-3.696V.5c-4.847.934-8.148 1.997-9.902 3.19-1.754 1.192-3.064 3.265-3.931 6.217-1.014-3.062-2.317-5.134-3.91-6.218C9.164 2.606 5.856 1.543.834.5v1.955z',
}

const iconPaper = {
  viewBox: '0 0 110 110',
  d: 'M55 0c30.3 0 55 24.7 55 55s-24.7 55-55 55S0 85.3 0 55 24.7 0 55 0zM7.7 55c0 13.07 5.316 24.91 13.9 33.475V26.1h7.8v68.668A47.048 47.048 0 0055 102.3a47.07 47.07 0 0028.301-9.412L83.3 45.8C78 45.4 68.8 43.3 64 34.4 59.1 25.4 53.1 22 42.6 22l-21.468-.001C12.822 30.524 7.7 42.17 7.7 55zM80 83.2V91H32.9v-7.8H80zM55 7.7a47.043 47.043 0 00-23.923 6.498L42.5 14.2c13.4 0 22.1 5.1 28.3 16.5 4.5 8.3 16 7.4 16.1 7.4l4.2-.4.002 47.844C98.086 77.3 102.3 66.637 102.3 55c0-13.095-5.337-24.957-13.952-33.527L82.591 33.17l-6.998-3.445 6.604-13.414A47.059 47.059 0 0055 7.7z',
};

const iconMural = {
  viewBox: '0 0 110 110',
  d: 'M55 0c30.3 0 55 24.7 55 55s-24.7 55-55 55S0 85.3 0 55 24.7 0 55 0zm-3.9 45.7h-23l-.001 48.197a47.025 47.025 0 0023.002 8.244L51.1 45.7zm30.8 0h-23v56.441a47.025 47.025 0 0023.001-8.244L81.9 45.7zM7.7 55c0 12.395 4.782 23.685 12.599 32.124V22.876C12.482 31.315 7.7 42.605 7.7 55zm82.001-32.124v64.248C97.518 78.685 102.3 67.395 102.3 55c0-12.395-4.782-23.685-12.599-32.124zM81.9 21.9H28.1v16h53.8v-16zM55 7.7a47.043 47.043 0 00-23.923 6.498h47.846A47.043 47.043 0 0055 7.7z',
};

const iconPainting = {
  viewBox: '0 0 110 110',
  d: 'M55 0c30.3 0 55 24.7 55 55s-24.7 55-55 55S0 85.3 0 55 24.7 0 55 0zm31.01 90.702H23.99C32.297 97.924 43.143 102.3 55 102.3c11.857 0 22.703-4.376 31.01-11.598zM20.5 36.8V22.66C12.564 31.12 7.7 42.497 7.7 55a47.066 47.066 0 009.115 27.9H51.1V67.5H35.8v-7.8h38.5v7.8H58.9v15.4h34.285A47.066 47.066 0 00102.3 55c0-12.501-4.864-23.878-12.8-32.34V36.8h-7.8v-6.6H28.3v6.6h-7.8zm23.1 0v16.3h-7.8V36.8h7.8zm22.9 0v8.4h7.7V53H58.7V36.8h7.8zM58.9 7.859V22.4h30.354C81.475 14.23 70.808 8.832 58.9 7.858zm-38.154 14.54L51.1 22.4V7.859c-11.908.974-22.575 6.37-30.354 14.54z',
};

const iconPublications = {
  viewBox: '0 0 110 110',
  d: 'M55 0c30.3 0 55 24.7 55 55s-24.7 55-55 55S0 85.3 0 55 24.7 0 55 0zM7.7 55c0 25.924 20.915 47.014 46.773 47.297-2.147-9.56-3.233-14.87-4.973-22.797-2.6-11.6 6.4-18.8 8-19.9.3-.3.6-.5.9-.8 3.4-2.8 8.9-7.5 8.5-10.5-.2-1.7-2.7-3.8-6.9-5.7-14.7-6.8-27.6 15.3-27.7 15.5l-1.8 3.2-9.8-4.9V22.449C12.644 30.932 7.7 42.394 7.7 55zm85.558-27.8H28.5v22.6c1.8-2.5 4.3-5.5 7.4-8.3 9-8.1 18.5-10.2 27.4-6.1 7 3.2 10.8 7 11.4 11.7 1 7.2-6.1 13.2-11.2 17.5-.3.3-.7.6-1 .9l-.2.2-.1.1c-.4.3-6.6 4.8-5 12 1.985 9.264 3.166 15.043 5.148 23.926C84.966 98.194 102.3 78.6 102.3 55a47.065 47.065 0 00-9.042-27.8zM55 7.7c-11.913 0-22.805 4.416-31.126 11.7h62.252C77.805 12.115 66.913 7.7 55 7.7z',
};

const iconCV = {
  viewBox: '0 0 110 110',
  d: 'M55 0c30.3 0 55 24.7 55 55s-24.7 55-55 55S0 85.3 0 55 24.7 0 55 0zm18.163 62.699H58.899V85.9H74v7.8H35.9v-7.8h15.2V62.699H36.03A68.089 68.089 0 0034.2 66.3c-6.127 13.242-5.906 24.434-5.804 27.398l.004.102-.438.002A47.058 47.058 0 0055 102.3a47.052 47.052 0 0026.375-8.043l.125.443c-3.495-12.19-6.223-22.905-8.337-32.001zm16.126-40.262L90.5 22c-5.341 1.909-10.309 3.953-14.916 6.138l-1.057.506.03.212c1.036 7.227 4.548 28.364 13.465 59.99C96.829 80.254 102.3 68.259 102.3 55c0-12.612-4.95-24.079-13.01-32.563zM55 7.7C28.9 7.7 7.7 28.9 7.7 55c0 12.6 4.94 24.057 12.988 32.54.409-5.834 1.856-14.486 6.312-24.14 4.4-9.6 10.8-18.2 19.1-25.5 9.28-8.2 20.915-14.937 36.587-21.238A47.046 47.046 0 0055 7.7zm12.17 24.814l-.601.344C55.703 39.146 47.273 46.452 41.018 54.9H71.41c-2.203-10.16-3.515-17.714-4.24-22.386zm7.356-3.883l.001.014v-.001l-.001-.013z',
};

export {
  iconPublications,
  iconCV,
  shortArrow,
  longArrow,
  iconMural,
  iconPainting,
  iconPaper,
  iconDivider,
  iconEye,
  iconQuote,
  selectArrow
};