import React from 'react';

const Beta = props => (
  <svg viewBox="0 0 204 474" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M64.774 346.746c0 10.576 2.001 20.41 6.024 29.498 4.012 9.1 9.401 17.03 16.167 23.789 6.757 6.77 14.683 12.162 23.778 16.177 9.084 4.023 18.911 6.025 29.482 6.025V237h-75.45v109.746zm0-294.982c1.269 20.725 8.877 38.062 22.825 52.018 13.95 13.956 31.485 20.934 52.626 20.934V51.764h-75.45zM177 459.03h-36.775c-15.217 0-29.482-2.855-42.798-8.564-13.314-5.71-25.044-13.53-35.189-23.472-10.144-9.932-18.289-21.569-24.41-34.891-6.133-13.321-9.413-27.595-9.828-42.82V200.206h112.224v-36.159c-15.642-.843-30.334-4.332-44.064-10.467-13.742-6.125-25.68-14.273-35.824-24.424-10.145-10.15-18.07-21.885-23.776-35.207C30.853 80.627 28 66.572 28 51.764V14.97h149v444.06z"/>
    <path d="M154.185 138.6h-13.917c-24.885 0-45.92-8.416-62.525-25.015-16.374-16.368-25.425-36.898-26.902-61.02l-.904-14.762h104.248v100.796zM78.649 250.912v95.862c0 8.714 1.582 16.522 4.839 23.874 3.306 7.492 7.777 14.075 13.294 19.583 5.524 5.53 12.11 9.998 19.587 13.296a55.1 55.1 0 009.981 3.329V250.913H78.65zM191 473h-50.732c-17.06 0-33.32-3.26-48.328-9.69-14.864-6.369-28.148-15.226-39.485-26.328-11.329-11.081-20.53-24.213-27.344-39.025-6.902-14.98-10.64-31.221-11.106-48.264l-.005-.38V186.286h112.35v-9.672c-12.526-1.83-24.547-5.295-35.873-10.351-15.224-6.782-28.697-15.967-40.028-27.296-11.367-11.362-20.368-24.677-26.755-39.574C17.261 84.387 14 68.347 14 51.715V1h177v472z"/>
  </svg>
);

export default Beta;
